@import '../../../../defaults';

@include media-breakpoint-up(lg) {
  body {
    overflow: initial !important;
    padding-right: 0 !important;
  }
}

.anw-search-offcanvas {
  overflow: auto;

  &.offcanvas-lg.offcanvas-start {
    width: 400px;

    @include media-breakpoint-up(lg) {
      width: 25%;
    }
  }

  .anw-close {
    font-size: $font-size-base * 1.5;
  }

  .anw-filter-heading {
    font-size: var(--anw-h3-font-size-lg);
    line-height: var(--anw-h3-line-height-lg);
  }

  .anw-filter-buttons {
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.255) 0, rgba(255, 255, 255, 1) 20%);
    z-index: 5;
  }

  @include media-breakpoint-up(lg) {
    border-right: none;
    box-shadow: none;
    overflow: unset;
    position: initial;
    transform: initial;
    visibility: initial !important;
    z-index: 0 !important;

    & ~ .offcanvas-backdrop.show {
      display: none;
    }
  }

  @include media-breakpoint-only(lg) {
    input.anw-input-box-width {
      width: 55px;
    }
  }

  @include media-breakpoint-down(md) {
    &.offcanvas-start {
      width: 100%;
    }

    .card {
      border: none;
    }

    .vue-slider {
      padding-left: map-get($spacers, 4) !important;
      padding-right: map-get($spacers, 4) !important;
    }

    .anw-rate label,
    .anw-disabled-rating-star {
      font-size: $font-size-lg;
    }

    .vue-slider-dot-handle {
      height: 130% !important;
      width: 130% !important;
      margin-top: -3px !important;
    }
  }

  @include media-breakpoint-down(xs) {
    .anw-rate label,
    .anw-disabled-rating-star {
      font-size: 1.375rem;
    }
  }
}
